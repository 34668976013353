(function () {
  'use strict';

  class TreeNodeCtrl {
    constructor(DiscussionsFlow, $scope, $state, $rootScope, Modules, $mdToast, Utils, CloneResourceFlow, Resources) {
      let vm = this;
      vm.ctrlName = 'TreeNodeCtrl';
      vm.DiscussionsFlow = DiscussionsFlow;
      vm.CloneResourceFlow = CloneResourceFlow;
      vm.Resources = Resources;
      vm.Utils = Utils;
      vm.$scope = $scope;
      vm.$state = $state;
      vm.user = $rootScope.user;
      vm.Modules = Modules;
      vm.$mdToast = $mdToast;
    }
    remove(module, node, scope) {
      scope.remove();
    }
    toggle(scope) {
      scope.toggle();
    }
    edit(node) {
      let vm = this;
      vm.$state.go('dashboard.learning.editResource', {id: node.id});
    }
    openMenu($mdMenu, ev) {
      $mdMenu.open(ev);
    }
    clone(e, node) {
      let vm = this;
      vm.CloneResourceFlow.show(e, node, (clone)=> {
        clone.id = node.id;
        vm.Resources.resourceClone(clone)
          .then(()=> {
            vm.getResources();
          });
      });
    }
  }

  /**
   * @ngdoc object
   * @name learning.index.controller:TreeNodeCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('TreeNodeCtrl', TreeNodeCtrl);
}());
